
import { Component, Vue } from "vue-property-decorator";
import { Toast } from "vant";
import { OrderService } from "src/services";
import physical from "./sub/physical.vue";
import globalConfig from "src/config/index";

@Component({
    components:
    {
        physical,
    }
})
export default class OrderList extends Vue
{
    /**
     * 是否是ai跳转过来
     * @private
     * @returns boolean
     */
     private get isAi(): boolean
    {
        return (this.$route.query && this.$route.query.isAi == 'true') ? true : false;
    }

    /**
     * 搜索关键字
     * @private
     * @returns string
     */
    private filters: any =
    {
        status: ""
    };

    /**
     * 加载
     */
    private loading:boolean = false

    /**
     * 订单列表
     * @private
     * @returns Array<any>
     */
    private orderList: Array<any> = [];

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 订单状态
     * @private
     * @returns number
     */
    private get reserveType(): number
    {
        return +this.$route.query.type;
    }

    /**
     * 获取订单列表
     * @private
     * @returns void
     */
    private async getOrderList(): Promise<void>
    {
        try
        {
            let employerMemberId = this.$route.query.employerMemberId || '';
            let data = {
                employerMemberId
            };
            let { content: result } = await OrderService.instance.getOrderList(data);
            if(result.data)
            {
                this.orderList = result.data || [];
            }
        }
        catch(err)
        {
            Toast(err);
        }
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        this.getOrderList();
        
        window.addEventListener('pageshow', () => {
            this.getOrderList();
            //外链回退到vue应用要执行的代码
            
        })
        
    }

    /**
     * 刷新数据
     * @private
     * @returns void
     */
    private onRefresh(): void
    {
        setTimeout(() => {
            Toast('刷新成功');
            this.loading = false;
        }, 1000);

        this.getOrderList();
    }
}
