
import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class Goods extends Vue
{
    /**
     * 是否是ai跳转过来
     * @private
     * @returns boolean
     */
     private get isAi(): boolean
    {
        return (this.$route.query && this.$route.query.isAi == 'true') ? true : false;
    }

    /**
     * 数据
     * @private
     * @returns string
     */
    @Prop({type: Object, default: ()=>({})})
    private item: any;

    /**
     * 跳转详情
     * @private
     * @returns string
     */
    private onDetail(item): any
    {
        this.$router.push({name:"orderDetail", params:{id:item.id},query:{reportId:item.reportId,isRead:item.isRead, isAi: this.isAi + ""}})
    }

    /**
     * 获取企业参数配置
     * @private
     * @returns any
     */
    private get generalSettings(): any
    {
        let generalSettingsValue = localStorage.getItem("generalSettings");
        if(generalSettingsValue)
        {
            return JSON.parse(generalSettingsValue);
        }
        return [];
    }

    /**
     * 是否显示配置
     * @protected
     * @returns boolean
     */
    protected showStting(code: string): boolean
    {
        // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
        let data = this.generalSettings.filter(res => res.code == code);
        if (data.length > 0)
        {
            return data[0].isOpen;
        }
        else
        {
            return true;
        }
    }
}
