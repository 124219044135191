
import { Component, Vue } from "vue-property-decorator";
import { GaodeService, OrganizationService, WechatService,AddPackageService, PackageService, OrderService, FamilyService, ReportService } from "src/services";
import { Toast, Dialog } from "vant";
import dayjs from "dayjs";
import { CHECK_STATUS, ORDER_STATUS } from "src/enums";
import { Pay, tools } from "src/utils";
import globalConfig from "src/config/index";
import udialog from "src/components/dialog";
import router from "src/router";

Component.registerHooks([
    "beforeRouteEnter",
    "beforeRouteLeave",
    "beforeRouteUpdate" // for vue-router 2.2+
]);


@Component({
    components:{
        udialog
    }
})
export default class OfteUser extends Vue
{
    /**
     * 是否是ai跳转过来
     * @private
     * @returns boolean
     */
     private get isAi(): boolean
    {
        return (this.$route.query && this.$route.query.isAi == 'true') ? true : false;
    }

    /**
     * 是否显示延时
     * @private
     * @returns boolean
     */
    private loadingMode: boolean = true;

    /**
     * 是否显示弹框
     * @private
     * @returns boolean
     */
    private isShowDialog: boolean = false;

    /**
     * 弹框名称
     * @private
     * @returns boolean
     */
    private dialogName: string = "";

     /**
     * 弹框内容
     * @private
     * @returns boolean
     */
    private dialogContent: string = "";

    /**
     * 时间处理
     * @private
     * @returns 
     */
    private dayjs: any = dayjs;

    /**
     * 加项包详情弹框
     * @private
     * @returns boolean
     */ 
    private showAddPackModal: boolean = false;

    /**
     * 当前加项包的项目
     * @private
     * @returns Array<any>
     */ 
     private addPackItems: Array<any> = [];

    /**
     * 自选项目选中
     * @private
     * @returns any
     */
    private fixedCollapseActive: any = [];

    /**
     * 权益加项目选中
     * @private
     * @returns any
     */
    private rightsCollapseActive: any = [];

    /**
     * 自选项目选中
     * @private
     * @returns any
     */
    private collapseActiveNames: any = [];

    /**
     * 订单详情
     * @private
     * @returns 
     */
    private orderDetail: any = {};

    /**
     * 加项包显示加项
     * @private
     * @returns void
     */
     private async showAddPackageModal(id): Promise<void>
    {
        let {content: res} =  await AddPackageService.instance.getAddPackageItem(id);
        if(res.data)
        {
            this.addPackItems = res.data;
            this.showAddPackModal = true;
        }
        
    }
    
    /**
     * 根据预约日期判断能否取消，(今天12点前或前一天不能取消)
     * @private
     * @returns boolean
     */
    private get isCancel(): boolean
    {
        let orderDate = this.orderDetail.physicalTime;
        // 判断体检日期是不是明天
        let isTomorrow = dayjs().add(1,"day").format("YYYY-MM-DD") === dayjs(orderDate).format("YYYY-MM-DD");
        // 判断是否是今天
        let isToDay = dayjs(orderDate).format("YYYY-MM-DD") === dayjs().format("YYYY-MM-DD");
        if(isTomorrow)
        {
            return false;
        }
        // else if(isToDay && (+dayjs(orderDate).format("hh") < 12))
        else if(isToDay)
        {
            return false;
        }

        return  true;
    }

    /**
     * 获取当前登入企业相关配置
     * @private
     * @returns void
     */
    protected get employerConfig(): any
    {
        let appid = localStorage.getItem("appid");

        let employerConfig = globalConfig.employerConfig[appid];

        return employerConfig;
    }

    /**
     * 订单id
     * @private
     * @returns number
     */
    private get orderId(): number
    {
        if(this.$route.params && this.$route.params.id)
        {
           return +this.$route.params.id;
        }

        return null;
    }


    /**
     * 页面是否回退-h5环境
     * @private
     * @returns boolean
     */
    private get goback(): boolean
    {
        if(this.$route.query && this.$route.query.goback)
        {
           return true;
        }

        return false;
    }

    /**
     * 组件创建钩子
     * @private
     * @returns void
     */
    private created(): void
    {
        if (!tools.isWechat()) // h5支付跳转
        {
            if (this.goback)
            {
                if (localStorage.getItem("h5Pay"))
                {
                    Dialog.confirm({
                        title: "支付结果",
                        message: "请确定微信支付是否已完成?",
                        confirmButtonText: "已完成支付",
                        cancelButtonText: "重新支付"
                    })
                    .then(() =>
                    {
                        localStorage.removeItem("h5Pay");
                        setTimeout(() => {
                            this.loadingMode = false;
                            this.getOrderDetail();
                        }, 500);
                    })
                    .catch(() =>
                    {
                        localStorage.removeItem("h5Pay");
                        setTimeout(() => {
                            this.loadingMode = false;
                            this.getOrderDetail();
                        }, 500);
                    });
                }
            }
        }
        if(this.orderId)
        {
            setTimeout(() => {
                this.loadingMode = false;
                this.getOrderDetail();
            }, 500);
        }
        
    }

    /**
     * 获取订单详情
     * @private
     * @param {params}
     * @returns {Promise<void>}
     */
    private async getOrderDetail(): Promise<void>
    {
        try
        {
            let {content: result} =  await OrderService.instance.getOrderDetail(this.orderId);

            if(result)
            {
                this.orderDetail = result.data;
                // 处理默认选择
                result.data && result.data.baseItemTypes && result.data.baseItemTypes.forEach((item, index)=>
                {
                    this.fixedCollapseActive.push(item.itemTypeId);
                })
                // 加项
                result.data && result.data.optionalItemTypes && result.data.optionalItemTypes.forEach((item, index)=>
                {
                    this.collapseActiveNames.push(item.itemTypeId);
                   
                })
                // 加项包
                result.data && result.data.addOnPackages &&  result.data.addOnPackages.forEach((item, index)=>
                {
                    this.collapseActiveNames.push(item.itemTypeId);
                   
                })
            }
        }
        catch(err)
        {
            Toast(err);
        }
        
    }

    /**
     * 组件进来之前
     * @private
     * @returns void
     */
    private beforeRouteEnter(to, from, next): void
    {
        if (to.query && to.query.isAi)
        {
            next();
        }
        else
        {
            let appid = localStorage.getItem("appid");
            if (!tools.isAndroid() && `/${appid}${to.path}` !== location.pathname)
            {
            location.assign(`/${appid}${to.fullPath}`);
            }
            else
            {
                next();
            }
        }
    }

    /**
     * 详情
     * @private
     * @returns void
     */
    private onSpecialDetail(id: string): void
    {
        this.$router.push({name:"rightsDetail", params:{id: id}})
    }

    /**
     * 显示提示
     * @private
     * @returns void
     */
    private showTip(remark: string,name:string): void
    {
        // Dialog.alert({
        //     messageAlign: "left",
        //     confirmButtonColor: "#00d5c1",
        //     message: remark,
        //     confirmButtonText: "确定"
        // });

        this.dialogName = name
        this.dialogContent = remark
        this.isShowDialog = true
    }

    /**
     * 跳转页面
     * @private
     * @returns void
     */
    private onGopage(name: string): void
    {
        this.$router.push({name});
    }

    /**
     * 获取企业参数配置
     * @private
     * @returns any
     */
    private get generalSettings(): any
    {
        let generalSettingsValue = localStorage.getItem("generalSettings");
        if(generalSettingsValue)
        {
            return JSON.parse(generalSettingsValue);
        }
        return [];
    }

    /**
     * 跳转地图
     * @private
     * @returns void
     */
    private async openMap(hospitalOut: any): Promise<any>
    {
        if(hospitalOut)
        {
            let lat = hospitalOut.latitude;
            let lng =hospitalOut.longitude;;
            let address = hospitalOut.address;
            let orgName = hospitalOut.hospitalName;

            if(tools.isWechat())
            {
                let currentUrl = location.href;
                let {content: result} = await WechatService.instance.getWechatJSSDK(currentUrl);
                if(result.data)
                {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: result.data.appId, // 必填，公众号的唯一标识
                        timestamp: result.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: result.data.nonceStr, // 必填，生成签名的随机串
                        signature: result.data.signature, // 必填，签名
                        jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
                    });

                    wx.ready(function()
                    {
                        wx.openLocation({
                            latitude: parseFloat(lat), // 纬度，范围为-90~90，负数表示南纬
                            longitude: parseFloat(lng), // 经度，范围为-180~180，负数表示西经
                            scale: 14, // 缩放比例
                            name: orgName,
                            address: address, // 地图缩放级别,整形值,范围从1~28。默认为最大
                            infoUrl: ""
                        });
                    });
                }
            }
            else
            {
                // 腾讯地图
                // let href = `http://apis.map.qq.com/uri/v1/marker?marker=coord:${lat},${lng};addr:${this.orgDetailInfo.org_name}`;
                // 高德
                let href = `http://uri.amap.com/marker?position=${lng},${lat}&name=${orgName}&coordinate=gaode&callnative=1`;
                window.open(href);
            }

        }

    }

    /**
     * 是否显示配置
     * @protected
     * @returns boolean
     */
    protected showStting(code: string): boolean
    {
        // A1001 -- 体检套餐列表页、详情页、提交订单页及订单详情页均不显示体检套餐价格及企业报销金额。
        // A1002 -- 体检套餐列表页、详情页均不显示体检套餐划线价格。
        let data = this.generalSettings.filter(res => res.code == code);
        if (data.length > 0)
        {
            return data[0].isOpen;
        }
        else
        {
            return true;
        }
    }

    /**
     * 复制
     * @param str 
     */
    private async copyOrderNo(str): Promise<void>
    {
        let save = function (e) {
            e.clipboardData.setData("text/plain", str);
            e.preventDefault();
        };
        let input = document.createElement("input");
        input.value = str;
        document.body.appendChild(input);
        input.select();
        input.setSelectionRange(0, input.value.length), document.execCommand("Copy");
        document.body.removeChild(input);
        Toast({
            message: "复制成功",
            duration: 1000
        });
    }

}
